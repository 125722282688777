// Build information, automatically generated by `ng-info`
const build = {
    version: "4.10.2",
    timestamp: "Sat Mar 29 2025 13:38:07 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "master",
        hash: "4b2aa4",
        fullHash: "4b2aa405168c656bc767244b1cc4fd578e22d035"
    }
};

export default build;